import React , { useState }  from 'react';
import { Button } from 'react-bootstrap';
import audi from "../Componente/assets/brandlogo/audi.png"
import bmw from "../Componente/assets/brandlogo/bmw.png"
import ford from "../Componente/assets/brandlogo/ford.png"
import hyundai from "../Componente/assets/brandlogo/hyundai.png"
import mercedes from "../Componente/assets/brandlogo/mercedes.png"
import opel from "../Componente/assets/brandlogo/opel.png"
import peugeot from "../Componente/assets/brandlogo/peugeot.png"
import renault from "../Componente/assets/brandlogo/renault.png"
import skoda from "../Componente/assets/brandlogo/skoda.png"
import toyota from "../Componente/assets/brandlogo/toyota.png"
import volkswagen from "../Componente/assets/brandlogo/volkswagen.png"
import fiat from "../Componente/assets/brandlogo/fiat.png"
import aston from "../Componente/assets/brandlogo/aston.png"
import iveco from "../Componente/assets/brandlogo/iveco.png"
import jaguar from "../Componente/assets/brandlogo/jaguar.png"
import landrover from "../Componente/assets/brandlogo/landrover.png"
import mini from "../Componente/assets/brandlogo/mini.png"
import nissan from "../Componente/assets/brandlogo/nissan.png"
import alfaromeo from "../Componente/assets/brandlogo/alfaromeo.png"
import jeep from "../Componente/assets/brandlogo/nissan.png"
import seat from "../Componente/assets/brandlogo/seat.png"
import suzuki from "../Componente/assets/brandlogo/suzuki.png"
import mitsubishi from "../Componente/assets/brandlogo/mitsubishi.png"
import volvo from "../Componente/assets/brandlogo/volvo.png"
import chevrolet from "../Componente/assets/brandlogo/chevrolet.png"
import honda from "../Componente/assets/brandlogo/honda.png"

const FilterBrand = ({ handleFilterByMarca }) => {
    const toggleBrandList = () => {
        const brandListWrapper = document.querySelector('.brand-list-wrapper');
        const body = document.querySelector('body');
        brandListWrapper.classList.toggle('list-open');
        body.classList.toggle('overflow-hidden');

    };



    const brands = [
        {
            name: "AUDI",
            icon: audi
        },
        {
            name: "BMW",
            icon: bmw
        },
        {
            name: "FORD",
            icon: ford
        },
        {
            name: "HYUNDAI",
            icon: hyundai
        },
        {
            name: "MERCEDES-BENZ",
            icon: mercedes
        },
        {
            name: "OPEL",
            icon: opel
        },
        {
            name: "PEUGEOT",
            icon: peugeot
        },
        {
            name: "RENAULT",
            icon: renault
        },
        {
            name: "SKODA",
            icon: skoda
        },
        {
            name: "TOYOTA",
            icon: toyota
        },
        {
            name: "VOLKSWAGEN",
            icon: volkswagen
        },
        {
            name: "FIAT",
            icon: fiat
        },
        {
            name: "ASTON MARTIN",
            icon: aston
        },
        {
            name: "IVECO",
            icon: iveco
        },
        {
            name: "JAGUAR",
            icon: jaguar
        },
        {
            name: "LAND ROVER",
            icon: landrover
        },
        {
            name: "MINI COOPER",
            icon: mini
        },
        {
            name: "NISSAN",
            icon: nissan
        },
        {
            name: "ALFA ROMEO",
            icon: alfaromeo
        },
        {
            name: "JEEP",
            icon: jeep
        },
        {
            name: "SEAT",
            icon: seat
        },
        {
            name: "SUZUKI",
            icon: suzuki
        },
        {
            name: "MITSUBISHI",
            icon: mitsubishi
        },
        {
            name: "VOLVO",
            icon: volvo
        },
        {
            name: "CHEVROLET",
            icon: chevrolet
        },
        {
            name: "HONDA",
            icon: honda
        },

    ]


    return (
        <div>

            <div className="container mt-4">
                <div className="row mb-4 mb-md-5">
                    <div className="col-md-12">
                        <div className="text-center">
                            <h1 className="title ">
                                Alege viitoarea ta <span className='text-primary '> mașină </span>
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="row mb-4 mb-xl-5">
                    <div className="col-md-12">
                        <button
                            className="btn d-inline-flex align-items-center d-lg-none p-0"
                            onClick={toggleBrandList}
                        >
                            <span className="icon fa-solid fa-arrow-left text-primary mr-2"></span>
                            <span className="">Alege după marcă</span>
                        </button>
                        <div className="brand-list-wrapper">
                            <div className="d-flex align-items-center justify-content-between d-lg-none p-3 border-bottom">
                                <span>Alege după marcă</span>
                                <button className="btn p-0" onClick={toggleBrandList}>
                                    <span className="icon fa-solid fa-xmark"></span>
                                </button>
                            </div>

                            <div className="brands-list d-flex flex-wrap justify-content-center">
                            <div className="brand-holder ">
                                <div className="brand all" style={{cursor:"pointer"}}>
                                  
                                    <div className="icon fa-solid fa-car"></div>
                                    <div className="brand__name mt-2 text-primary" style={{cursor:"pointer"}} onClick={() => handleFilterByMarca("")}>
                                        TOATE
                                    </div>
                                </div>
                            </div>
                                {brands.map((brand,index) =>(
                                <div className="brand-holder " key={index}>
                                    <div className="brand"style={{cursor:"pointer"}} >
                                        <p onClick={() => handleFilterByMarca(`${brand.name}`) } className="a-absolute"></p>
                                        <img src={brand.icon} alt={brand.name} className="img-fluid brand__logo" loading="lazy" />
                                        <div className="mt-3">
                                           {brand.name}
                                        </div>
                                    </div>
                                </div>
                                   ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterBrand;
