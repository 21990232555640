import React , {useEffect}from 'react'

import logo from "../Componente/assets/logonavbar.png"

const Footer = () => {
   


    return (
        <div>
            <br />
            <br />
            <footer>
                <section className="bg-black py-5 ">
                    <div className="container pt-xl-2">
                        <div className="row ">
                            <div className="col-sm-12 col-md-7 col-xl-8 mb-4  text-left">
                               
                                <a className="navbar-brand py-3" href="/"  aria-label="Link către acasa">
                   <img src={logo}  alt="Parc Auto Timisoara" loading='lazy'/>
                    </a>

                             
                                <div>
                                    <p className='text-light'>Parc auto autorizat în comerțul cu autoturisme.</p>
                                </div>
                                <div>
                                    <a href="/politicadeconfidentialitate"
                                        className="btn-link font-xs text-white-50">Politica de confidențialitate</a>
                                </div>

                            </div>

                            <div className="col-sm-12 col-md-5 col-xl-4 text-xl-right ">
                                <div className="row footer-info text-left">
                                    <div className="col-sm-4 col-md-6 ">
                                        <span className="text-primary fa-solid fa-clock  font-xl mr-3 mb-2"></span>
                                        <div className="text-uppercase font-weight-light">
                                            <div className="mb-1">
                                                Luni - Vineri
                                                <div className="">
                                                    09:00 - 18:00
                                                </div>
                                            </div>

                                            <div className="mb-1">Sâmbătă
                                                <div className="">
                                                    10:00 - 16:00
                                                </div>
                                            </div>
                                            <div className="mb-4">Duminică
                                                <div className="">
                                                    10:00 - 14:00
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-6 ">
                                        <span className="fa-solid fa-location-dot text-primary font-xl mb-2 mr-3"></span>
                                        <div className="text-uppercase font-weight-light">
                                            <div className="mb-3">
                                                <a target='_blank'
                                                className='btn-link text-white'
                                                 href="https://www.google.com/maps/place/MDB+Company/@45.7690535,21.2652434,17z/data=!3m1!4b1!4m20!1m13!4m12!1m4!2m2!1d21.3122726!2d46.1865618!4e1!1m6!1m2!1s0x474567156576a703:0xc3229aae8a062af5!2smdb+timisoara!2m2!1d21.2678338!2d45.7690161!3m5!1s0x474567156576a703:0xc3229aae8a062af5!8m2!3d45.7690535!4d21.2678183!16s%2Fg%2F11cnd722s_?entry=ttu">
                                                    TIMIȘOARA,  CALEA DOROBANȚILOR NR. 92 - 94
                                                </a>
                                            </div>
                                            <div className="mb-2">
                                                <div className="">
                                              <a href='tel:+40729475100' className='btn-link text-white'>
                                                    <i className="fa-solid fa-phone text-primary font-md mr-2"></i>
                                                    0729 475 100
                                                </a>  
                                                </div>
                                            </div>
                                            <div className="mb-2">
                                                <div className="">
                                                <a href='tel:+400727521311' className='btn-link text-white'>
                                                    <i className="fa-solid fa-phone text-primary font-md mr-2"></i>
                                                    0727 521 311
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="col-sm-5 col-xl-5 text-xl-right">
                                <section className="bg-black">
                                </section>
                            </div>


                        </div>
                    </div>

                    <div className="container-fluid bg-black  pb-0">
                        <div className="row align-items-center ">
                            <div className="col-md-12 text-white text-center">
                                <div className="font-xs">
                                    <span>© 2023 MDB Company SRL.</span>
                                    <span>Toate drepturile rezervate.</span>
                                </div>

                                <a className="d-inline-block font-xs text-white-60 " title="Creare Website - Arad"
                                    target="_blank" href="https://smartweblab.ro/">
                                    Design by Smart Web Lab
                                </a>
                            </div>



                        </div>
                    </div>
                </section>


            </footer>
        </div>
    )
}

export default Footer