import React , {useEffect, useState } from 'react'

import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import slide1 from './assets/images/slide1.jpg'
import slide2 from "./assets/images/slide4.png"
import slide3 from "./assets/images/slide3.jpg"
import slide5 from "./assets/images/slider5.png"


import AOS from 'aos';
import 'aos/dist/aos.css';


const MainSlider = () => {
  const images = [
    {
        imageUrl: slide1,
        title: 'Mașini rulate',
        subtitle: 'Import Germania\n Import Austria',
        link: '/masini',
        buttonText: 'Listă mașini',
    },
    {
        imageUrl: slide2,
        title: 'Finanțare',
        subtitle: 'Rate fixe',
        link: '/masini',
        buttonText: 'Listă mașini',
    },
    {
        imageUrl: slide3,
        title: 'Descoperă ',
        subtitle: 'Flota noastră de autoturisme',
        link: '/masini',
        buttonText: 'Listă mașini',
    },
   
    // Adaugă aici celelalte slide-uri ca obiecte în array
];

const [title, setTitle] = useState("2.5rem");
const [buton, setButon] = useState("1rem");
const [subtitle, setSubTitle] = useState("1.75rem");


useEffect(() => {
  const handleResize = () => {
    if (window.innerWidth <= 576) { // Dacă ecranul are lățimea mai mică sau egală cu 576px (sm)
      setTitle("1.5rem");
    setButon(".8rem")
    setSubTitle("1rem")
    } else {
      setTitle("2.5rem");
      setButon("1rem")
      setSubTitle("1.75rem")
    }
  };

  window.addEventListener('resize', handleResize);

  // Apelăm funcția pentru a seta înălțimea inițială
  handleResize();

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

  

    
   

  return (
    <div className="custom-container ">
                   

        <div  className="carousel slide swiper main-photo mb-4 "  >
            <Swiper className="swiper-wrapper"
                modules={[Navigation, Autoplay]}
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{
                  delay: 7000, // Setează aici timpul în milisecunde (în acest caz, 5000 ms sau 5 secunde)
                  disableOnInteraction: false, // Dacă dorești ca autoplay să nu fie oprit când utilizatorul interacționează
                }}
                loop={true}
               
                
            >
               <SwiperSlide className='d-flex justify-content-start align-items-start  ' 
                   style={{backgroundColor:"black"}}  >
                     
                        <img src={slide5} />
                       
                      
                    </SwiperSlide>


                {images.map((image, index) => (
                    <SwiperSlide key={index} className='d-flex justify-content-start align-items-start  ' 
                    style={{ backgroundImage: `url('${image.imageUrl}') `, minHeight: '100%', backgroundSize: "cover" ,backgroundPosition:"center" ,opacity:"1.3"}} >
                       <div className=' animate-slider1  triunghi  d-flex'>
                          <div className='triunghi-content text-center'>
                        <h1 className="ml-1 text-light" style={{fontSize:title}}  >
                           {image.title}
                        </h1>
                        <span className=' ml-1 text-light font-xl fw-bold'>
                        {image.subtitle.split('\n').map((line, lineIndex) => (
                        <span key={lineIndex}>{line}<br/></span>
                    ))}
                          </span>

                        
                          <div className='mt-3 mb-2  ml-1' >
                           
                        <a href={image.link} className="btn btn-primary rounded-pill text-uppercase  py-1" aria-label="Link către anunț"  style={{fontSize:buton}} >
                            {image.buttonText}
                        </a>
                        </div>
                          </div>
                       
                       </div>
                    </SwiperSlide>
                ))}
                 
            </Swiper>
          
        </div>
    </div>


    

  )
}

export default MainSlider