import React,{useEffect} from 'react'
import { beneficii } from './Listabefenicii'
import AOS from 'aos';
import 'aos/dist/aos.css';

const CardBenefit = () => {

    useEffect(()=>{
        AOS.init()
   },[])


  return (
    <div className="col-md-12 col-xl-12 benefit-list">
    <div className="row ">
        {beneficii.map((beneficiu,index)=>(
 <div className="col-md-6 col-xl-4" key={index} data-aos="zoom-in" data-aos-duration="1000">
            <div className="benefit" >
                <div className="d-flex justify-content-between mb-3">
                    <div className="benefit__icon">
                        <img src={beneficiu.icon} alt={beneficiu.nume} className="img-fluid"/>
                    </div>
                    <div className="benefit__nr">{beneficiu.id}</div>
                </div>
                <div className="benefit__name mb-2 text-black">{beneficiu.nume}</div>
                <div className="benefit__brief text-black">
                 {beneficiu.descriere}
                </div>
            </div>
        </div>
        ))}
       </div>
</div>
  )
}

export default CardBenefit