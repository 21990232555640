import React, { useState, useEffect, useRef } from 'react';
import Navbar from "../src/Header/Navbar"
import MainSlider from './Componente/MainSlider';
import Masini from './Pages/Masini';
import Produs from './Componente/Produs';
import masina from "./Componente/assets/images/about-car.png"
import "../src/index.css"
import { Link } from 'react-scroll';
import AOS from 'aos';
import 'aos/dist/aos.css';
import carmoney from "./Componente/assets/images/carmoney.jpg"
import Footer from './Footer/Footer';
import SectionHome from './Componente/SectionHome';
import AboutUs from './Componente/AboutUs';

const Home = () => {


    useEffect(() => {
        AOS.init();

    },[])
  
    const [subtitle, setSubTitle] = useState("1.75rem");


useEffect(() => {
  const handleResize = () => {
    if (window.innerWidth <= 576) { // Dacă ecranul are lățimea mai mică sau egală cu 576px (sm)
    
    setSubTitle("1.4rem")
    } else {
     
      setSubTitle("1.75rem")
    }
  };

  window.addEventListener('resize', handleResize);

  // Apelăm funcția pentru a seta înălțimea inițială
  handleResize();

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);



    return (
        <div>
            
            <Navbar />

            
            <div>
                <MainSlider />
                <div className="container">
                    <div >
                    <h3 className='mt-3 mb-3 d-inline-block py-2 custom-border' style={{fontSize:subtitle}}>
                          ULTIMELE MAȘINI ADĂUGATE
                    </h3>

                    </div>
                    <Produs />
                   <AboutUs masina={masina}/>
                </div>
             <SectionHome carmoney={carmoney}/>
            </div >
            <Footer />
        </div >
    )
}

export default Home