import React ,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';


const AboutUs = ({masina}) => {
    useEffect(() => {
        AOS.init();

    },[])
  return (
    
       
                        <div className="container my-4 my-md-5 py-xxl-5">
                            <div className="row ">
                                <div className="col-sm-5 mt-2 py-3" data-aos="animate">
                                    <img
                                        src={masina}
                                        className=" img-fluid "
                                        style={{ maxHeight: "300px" }}
                                        alt="About us"
                                    />
                                </div>
                                <div className="col-sm-7 col-xl-7 col-md-12"  >
                                    <div className="title font-weight-black mb-3 mb-md-3 text-center mb-xl-3" data-aos="zoom-in">PARC AUTO TIMIȘOARA</div>
                                    <p className='ml-0' data-aos="fade-right">
                                    <i className="fa-solid fa-check text-primary"></i> Suntem parc auto autorizat în comerțul cu autoturisme import Germania. <br /> 
                                    <i className="fa-solid fa-check text-primary"></i> Toate mașinile noastre sunt verificate și testate înainte de a se expune spre vânzare în parc, având carte service cu istoric și km originali cu posibilitatea
                                        verficării prin seria de șasiu.
                                    </p>
                                    <div className='row'>
                                        <div className='col-sm-5 col-xl-5 '>
                                            <p className="font-weight-bold mb-2">Asigurăm:</p>
                                            <ul className="list-unstyled pl-2 pl-md-4">
                                                <li className="d-flex" data-aos="zoom-in" data-aos-duration="1000"><span className="fa-solid fa-circle-check font-md text-primary mr-2 mr-md-3 mt-2"></span> Numere roșii gratis
                                                </li>
                                                <li className="d-flex" data-aos="zoom-in" data-aos-duration="1200"><span className="fa-solid fa-circle-check font-md text-primary mr-2 mr-md-3 mt-2"></span> Livrare gratuită în Timișoara pe o rază de 100 de km
                                                </li>
                                                <li className="d-flex" data-aos="zoom-in" data-aos-duration="1300"><span className="fa-solid fa-circle-check font-md text-primary mr-2 mr-md-3 mt-2"></span> Garanție 12 luni
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-sm-7 col-xl-5">
                                            <p className="font-weight-bold mb-2">Autoturismele se pot achiziționa:</p>
                                            <ul className="list-unstyled pl-2 pl-md-4">
                                                <li className="d-flex" data-aos="zoom-in" data-aos-duration="1000"><span className="fa-solid fa-circle-check font-md text-primary mr-2 mr-md-3 mt-2"></span> Cash
                                                </li>
                                                <li className="d-flex" data-aos="zoom-in" data-aos-duration="1200"><span className="fa-solid fa-circle-check font-md text-primary mr-2 mr-md-3 mt-2"></span> Transfer bancar
                                                </li>
                                                <li className="d-flex" data-aos="zoom-in" data-aos-duration="1300"><span className="fa-solid fa-circle-check font-md text-primary mr-2 mr-md-3 mt-2"></span> Credit persoane fizice
                                                </li>
                                                <li className="d-flex" data-aos="zoom-in" data-aos-duration="1400"><span className="fa-solid fa-circle-check font-md text-primary mr-2 mr-md-3 mt-2"></span> Leasing persoane juridice
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   
    
  )
}

export default AboutUs