import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { app , auth, firestore ,storage } from '../firebase';
import { getFirestore, collection, getDocs, query, orderBy } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Search from '../Listing/Search'

import Pagination from "../Listing/Pagination"
import { Link } from "react-router-dom"
import GearBoxIcon from './GearBoxIcon';

import AOS from 'aos';
import 'aos/dist/aos.css';

const Produs = () => {
    const [data, setData] = useState([]);
  
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const postCollectionRef = collection(firestore, 'post');
                const q = query(postCollectionRef, orderBy('createdAt', 'desc'));
                const querySnapshot = await getDocs(q);
                const dataArray = [];
                querySnapshot.forEach((doc) => {
                    const docData = doc.data();
                    dataArray.push({ id: doc.id, ...docData }); // Adaugă ID-ul documentului în obiectul de date
                });

                setData(dataArray);
            } catch (error) {
            }
        };
        fetchData();
AOS.init()
    }, [firestore]);
    
    const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);

    useEffect(() => {
      const mediaQuery = window.matchMedia("(orientation: landscape)");
  
      const handleOrientationChange = (event) => {
        setIsLandscape(event.matches);
      };
  
      mediaQuery.addEventListener('change', handleOrientationChange);
  
      return () => {
        mediaQuery.removeEventListener('change', handleOrientationChange);
      };
    }, []);
   

  


  return (
    <div className="row">
    {data.slice(0, 12 ).map((masina, index) => (
           <div className={`col-md-${isLandscape ? 4 : 6} col-xl-3 product-holder`} key={index}>
                <div className="product" >
                    <Link to={`/detalimasina/${masina.id}`} aria-label="Link către anunț"  className="a-absolute"></Link>
                    {masina.mainImage && Object.keys(masina.mainImage).map((key) => (
                        <div className="product__img position-relative" key={`${masina.id}-${key}`}>
                              <div className="image-container">
                            <img src={masina.mainImage[key]} alt={masina.marca} className="img-fluid" loading="lazy" />
                            {masina.comanda ? (
                              <div className="text-overlay">
                              Disponibil pe comandă
                            </div>

                            ) : null

                            }
                        </div>
                        </div>
                    ))}
                    <div className="product__specs bg-secondary px-3 py-3">
                    <div className="mb-2 mb-md-2 mb-lg-2 mb-2">
  {masina.marca} {masina.model}- {masina.anFabricatie} <br/>
  {masina.titlu.length <= 39 ? masina.titlu : masina.titlu.slice(0, 39) + '...'}
</div>

                        <div className="pb-3 border-bottom border-dark-gray mb-3 d-md-flex-or-not d-lg-block d-xxl-flex-or-not d-xxl-block text-gray">
                            <div className="d-flex align-items-center justify-content-between ">
                                <span className="text-light">Garanție</span>
                                <span className="text-light">Km certificați</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between py-1 ml-auto mt-2 mt-md-0-or-not mt-lg-2 mt-xxl-0-or-not">

                                <div className="product__price px-2">
                                    {masina.pret.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €
                                </div>
                                <div className="font-xs  ">
                                    <span className="text-light">Posibilitate rate</span>
                                </div>
                            </div>
                        </div>
                        <div className="specs d-flex align-items-center justify-content-between">

                            <div className="d-flex align-items-center">
                                <span className="fa fa-road text-primary mr-2"></span>
                                <span>
                                    {masina.rulaj.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} Km
                                </span>
                            </div>

                            <div className="d-flex align-items-center mx-2">
                                <span className="fa-solid fa-gas-pump text-primary mr-2"></span>
                                <span>{masina.combustibil}</span>
                            </div>

                            <div className="d-flex align-items-center">
                                <span className=" text-primary mr-2"><GearBoxIcon/></span>
                                <span>{masina.cutieViteza}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        ))}
        <div className='col-12 mt-3'>
            <a className='btn btn-primary ' style={{fontSize:"1.3rem"}} href='https://parcautotimisoara.ro/masini'> Vezi toate mașinile</a>
        </div>
</div>
  )
}

export default Produs