import React, { useEffect, useState } from 'react';

import logo from "../Componente/assets/logonavbar.png"


const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
              setIsScrolled(true);
            } else {
              setIsScrolled(false);
            }
          };
          window.addEventListener('scroll', handleScroll);
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
        }, []);

    return (
        <div  className={`bg-black  ${isScrolled ? 'header py-0 sticky-top shadow-sm' : ''}`}>
         
                <nav className="navbar navbar-expand-lg container bg-black text-light">
                    <a className="navbar-brand py-3" href="/"  aria-label="Link către acasa">
                   <img src={logo}  alt="Parc Auto Timisoara" loading='lazy'/>
                    </a>
                    <div className="navbar-collapse bg-black" style={{zIndex:"9999"}}>
                        <div className="d-flex align-item-center justify-content-between mb-2 mb-md-2 pt-3 mt-0 border-bottom border-primary d-lg-none bg-black text-light menu-logo">
                           
                           <h2 className='mb-3 px-3'>Meniu</h2>
                            <button
                                className="btn d-md-none p-0 pr-3 mb-3"
                                aria-label="Close navigation"
                                onClick={() => {
                                    document.querySelector('.navbar-collapse').classList.remove('navbar-visible');
                                }}
                            >
                                 <i className="fa fa-times text-light"></i>
                            </button>
                        </div>
                        <ul className="navbar-nav ml-auto ">
                            <li className="nav-item">
                                <a className="nav-link active-or-not" href="/">
                                    Acasă
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/masini">
                                    Mașini
                                </a>
                            </li>
                           
                            <li className="nav-item">
                                <a className="nav-link" href="/credit-rate">
                                    Credit / Rate
                                </a>
                            </li>
                           
                            <li className="nav-item">
                                <a className="nav-link" href="/beneficii">
                                   Beneficii
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/contact">
                                    Contact
                                </a>
                            </li>
                        </ul>
                        <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                                <a className="nav-link" aria-label="Link către telefon" href="tel:+40729475100">
                                    Tel: 0729475100
                                </a>
                            </li>
                            <li className="nav-item">
                            <a className='nav-link' target='_blank' rel="noreferrer" aria-label="Link către WhatsApp" href="https://wa.me/+40729475100">
                                <span className="fa-brands fa-whatsapp text-green fa-xl"></span>
                             </a>
                            </li>
                        </ul>
                    </div>
                    <button
                        className="navbar-toggler"
                        type="button"
                        aria-label="Toggle navigation"
                        onClick={() => {
                            document.querySelector('.navbar-collapse').classList.toggle('navbar-visible');
                        }}
                    >
                       <i className="fa fa-bars text-light"></i> 
                    </button>
                </nav>
            
        </div>
    );
};

export default Navbar;
