import React,{useEffect} from 'react'
import Navbar from '../Header/Navbar';
import Footer from '../Footer/Footer';
import tbi from "../Componente/assets/images/tbi.png"
import bt from "../Componente/assets/images/logo_bt.jpg"
import banner from "../Componente/assets/images/banner-benefits.jpg"
import mogo from "../Componente/assets/mogo.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
const CreditRate = () => {
    useEffect(()=>{
        AOS.init()
   },[])

    return (
        <div>
            <Navbar />
            <section className="py-4 bg-light text-black"  >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="font-md text-uppercase">Mașini de vânzare în rate </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container my-4">
                <div className="row justify-content-between">
                    <div className="col-md-12 mt-2 mt-md-2 pt-xl-5">
                        <div className="text-center">
                            <span
                                className="title mr-4 mr-xl-5 d-block d-lg-inline mb-2 mb-lg-0">Experții noștri sunt alături de tine pe tot parcursul procesului de cumpărare</span>
                        </div>
                    </div>
                    <div className="col-md-12 col-xl-6 my-5">
                        <div className="pr-xl-5">
                            <div className="title mb-2 mb-xl-3">Credit auto Avantajos</div>
                            <div className="font-light mb-3 mb-md-4 mb-xl-5">
                                <p>
                                    Mașina visurilor tale se afla la un click distanță.
                                    Cumpără acum mașina dorită printr-un credit avantajos oferit de
                                    partenerul nostru TBI. Beneficiezi de rate fixe pe întreaga durată a contractului.
                                </p>
                                <a href="https://tbibank.ro/"  aria-label="Link către TBI Website" target="_blank">
                                    <img src={tbi}
                                        style={{ width: "550px" }}
                                        className="img-responsive img-fluid" />
                                </a>
                            </div>
                            <a href="https://wa.me/40729475100"
                                target="_blank"
                                className="btn btn-primary btn-custom">
                                <span className="d-inline-block px-xl-4 font-md">Aplică acum</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-12 col-xl-6 my-5 text-xl-right text-md-left text-sm-left">
                        <div className="pr-xl-5">
                            <div className="title mb-2 mb-xl-3">Credit Banca Transilvania</div>
                            <div className="font-light mb-3 mb-md-4 mb-xl-5">
                            <a href="https://www.bancatransilvania.ro/credite/credite-de-nevoi/credit-auto/"  aria-label="Link către BT Website" target="_blank">
                                <img src={bt}
                                    style={{ width: "300px" }}
                                    className="img-responsive img-fluid" />
                                    </a>
                            </div>
                            <a href="https://wa.me/40729475100"
                                target="_blank"
                                className="btn btn-primary btn-custom" aria-label="Link către whatsapp">
                                <span className="d-inline-block px-xl-4 font-md">Aplică acum</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-12 col-xl-12 my-5 text-xl-center text-md-left text-sm-left">
                        <div className="pr-xl-5">
                            <div className="title mb-2 mb-xl-3">Credit auto de la MOGO</div>
                            <div className="font-light mb-3 mb-md-4 mb-xl-5">
                            <p>
                            Cu ajutorul partenerului nostru MOGO poți avea rapid un credit adptaptat nevoilor tale.
                                </p>   
                                <a href="https://www.mogo.ro/"  aria-label="Link către Mogo Website" target="_blank">
                                <img src={mogo}
                                    style={{ width: "300px" }}
                                    className="img-responsive img-fluid" />
                                    </a>
                            </div>
                            <a href="https://wa.me/40729475100"
                                target="_blank"
                                className="btn btn-primary btn-custom" aria-label="Link către whatsapp">
                                <span className="d-inline-block px-xl-4 font-md">Aplică acum</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <section className="bg-light banner-credit" style={{ backgroundImage: `url('${banner}')` }}>
                <div className="container my-4">
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-xl-11">
                            <div className="row justify-content-between">
                                <div className="col-md-2">
                                </div>
                                <div className="col-md-12 col-lg-7 mb-5 mb-lg-0">
                                </div>

                                <div className="col-md-8 col-lg-5 col-xl-5" data-aos="fade-up">
                                    <div className="bg-white p-4 p-md-5 rounded-lg">
                                        <div className="font-md font-weight-semibold mb-3">Nu ai găsit răspunsul la întrebarea ta?
                                        </div>
                                        <div className="faq-contact">
                                            <div className="d-flex border-bottom pb-3 mb-3 contact-line">
                                                <span className="fa-solid fa-location-dot  font-md text-primary mt-1 mr-2"></span>
                                                <ul className="list-unstyled mb-0">
                                                    <li className="font-weight-semibold">Adresă</li>
                                                    <li className="font-small">
                                                        <a target='_blank' href="https://www.google.com/maps/place/MDB+Company/@45.7690535,21.2652434,17z/data=!3m1!4b1!4m20!1m13!4m12!1m4!2m2!1d21.3122726!2d46.1865618!4e1!1m6!1m2!1s0x474567156576a703:0xc3229aae8a062af5!2smdb+timisoara!2m2!1d21.2678338!2d45.7690161!3m5!1s0x474567156576a703:0xc3229aae8a062af5!8m2!3d45.7690535!4d21.2678183!16s%2Fg%2F11cnd722s_?entry=ttu">
                                                            TIMIȘOARA,  CALEA DOROBANȚILOR NR. 92 - 94
                                                        </a>

                                                    </li>
                                                    <li className="font-small"></li>
                                                </ul>
                                            </div>
                                            <div className="d-flex border-bottom pb-3 mb-3 contact-line">
                                                <span className="fa-solid fa-phone  font-md text-primary mt-1 mr-2"></span>
                                                <ul className="list-unstyled mb-0">
                                                    <li className="font-weight-semibold">Telefon</li>
                                                    <li className="font-small"><a href="tel:+40729475100"
                                                        className="btn-link text-dark hover--text-primary">0729 475 100</a>
                                                    </li>
                                                    <li className="font-small"><a href="tel:+40727521311"
                                                        className="btn-link text-dark hover--text-primary">0727 521 311</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="d-flex contact-line">
                                                <span className="fa-solid fa-clock font-md text-primary mt-1 mr-2"></span>
                                                <ul className="list-unstyled mb-0">
                                                    <li className="font-weight-semibold">Orar</li>
                                                    <li className="font-small">Luni - Vineri: 09:00 - 18:00</li>
                                                    <li className="font-small">Sâmbătă: 10:00 - 16:00</li>
                                                    <li className="font-small">Duminică: 10:00 - 14:00</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default CreditRate;