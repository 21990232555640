import React, { useEffect } from "react";
import SectionHome from "../Componente/SectionHome";
import carmoney from "../Componente/assets/images/carmoney.jpg";
import Navbar from "../Header/Navbar";
import Footer from "../Footer/Footer";
import CardBenefit from "../Componente/CardBenefit";
import AOS from "aos";
import "aos/dist/aos.css";
import SliderBrandLogo from "../Componente/SliderBrandLogo";

const Beneficii = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <Navbar />

      <section className="py-4 bg-light  text-black">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="font-md text-uppercase">
                Beneficii și Avantaje
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container mt-4 mt-md-5 my-lg-5">
          <div className="row">
            <CardBenefit />
           
            <div className="col-sm-12 " >
              <p className="bg-primary p-3 " style={{borderRadius:"12px" , color:"white" , fontSize:"1rem"}} data-aos="zoom-in" data-aos-duration="1000">

        Garanție extinsă pentru autovehiculele noi și second-hand Garanția
        Extinsă pentru Autovehicule acoperă reparațiile bruste ale motorului,
        transmisiei, componentelor mecanice, electrice și electronice ale
        autovehiculului dumneavoastră. Astfel, puteti evita cheltuielile
        neașteptate pentru reparații la diferite componente ale
        mașinii dumneavoastră.
              </p>
       
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light py-2 mb-5">
        <div className="container-fluid p-0" >
          <SliderBrandLogo />
        </div>
      </section>
      <SectionHome carmoney={carmoney} />
      <Footer />
    </div>
  );
};

export default Beneficii;
