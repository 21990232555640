
import DetaliMasina from '../src/MasinaPage/DetaliMasina';
import Home from './Home';
import Masini from '../src/Pages/Masini';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Asigură-te că ai importat 'Routes'
import Beneficii from './Pages/Beneficii';
import CreditRate from './Pages/CreditRate';
import Contact from './Pages/Contact';
import Politica from './Pages/Politica';


function App() {
  return (
  
    <Router>
      <Routes>
        <Route exact path="/" element={ <Home/>} />
        <Route path="/masini" element={<Masini/>} />
        <Route path="/detalimasina/:masinaId" element={<DetaliMasina/>} />
        {/* <Route path="/detalimasina" element={<DetaliMasina/>} /> */}
        <Route path="/beneficii" element={<Beneficii/>} />
        <Route path="/credit-rate" element={<CreditRate/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/politicadeconfidentialitate" element={<Politica/>} />
      </Routes>
    </Router>
  );
}

export default App;
