import React from 'react'

const CaracteristiciAuto = ({projectData ,trueAsistenaOptions,trueAudioOptions,trueComfortOptions, trueSigurantaOptions}) => {
  return (
    <div className="col-md-12 col-xl-7 pt-4 pt-md-0 mt-4 mt-5 mt-xl-5 mt-md-0">
     { projectData.comanda ? (

       
<div className='bg-primary text-white p-2 text-center h5  mb-4' style={{borderRadius:"8px"}}>
Doar pe comandă

</div>
  ) : (null)
}
              <div className="bg-light2 rounded-md p-3 p-md-4 mb-4 mb-md-0 mb-lg-5 mb-md-4">
                <div className="row">

                  <div className="col-md-6 my-2">
                    <div className="row mb-1">
                      <div className="col-6">
                        <div className="font-weight-semibold">Marca:</div>
                      </div>
                      <div className="col-6">
                        <div>
                        
                            {projectData.marca}
                         
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row my-2">
                      <div className="col-6">
                        <div className="font-weight-semibold"> Model:</div>
                      </div>
                      <div className="col-6">
                        <div>
                          {projectData.model}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row my-2">
                      <div className="col-6">
                        <div className="font-weight-semibold"> An fabricație:</div>
                      </div>
                      <div className="col-6">
                        <div>
                          {projectData.anFabricatie}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row my-2">
                      <div className="col-6">
                        <div className="font-weight-semibold"> Rulaj:</div>
                      </div>
                      <div className="col-6">
                        <div>
                          {projectData.rulaj.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} Km
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row my-2">
                      <div className="col-6">
                        <div className="font-weight-semibold"> Motor:</div>
                      </div>
                      <div className="col-6">
                        <div>
                          {projectData.motor} cm³
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row my-2">
                      <div className="col-6">
                        <div className="font-weight-semibold"> Putere:</div>
                      </div>
                      <div className="col-6">
                        <div>
                        {projectData.putere} CP
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row my-2">
                      <div className="col-6">
                        <div className="font-weight-semibold"> Combustibil:</div>
                      </div>
                      <div className="col-6">
                        <div>
                          {projectData.combustibil}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row my-2">
                      <div className="col-6">
                        <div className="font-weight-semibold"> Norma de poluare:</div>
                      </div>
                      <div className="col-6">
                        <div>
                         {projectData.normaPoluare}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row my-2">
                      <div className="col-6">
                        <div className="font-weight-semibold"> Cutie viteze:</div>
                      </div>
                      <div className="col-6">
                        <div>
                         {projectData.cutieViteza}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row my-2">
                      <div className="col-6">
                        <div className="font-weight-semibold"> Tracțiune:</div>
                      </div>
                      <div className="col-6">
                        <div>
                          {projectData.tractiune}
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-md-6">
                    <div className="row my-2">
                      <div className="col-6">
                        <div className="font-weight-semibold"> Caroserie:</div>
                      </div>
                      <div className="col-6">
                        <div>
                         {projectData.caroserie}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 my-2">
                    <div className="row mb-1">
                      <div className="col-6">
                        <div className="font-weight-semibold">Nr. locuri:</div>
                      </div>
                      <div className="col-6">
                        <div>{projectData.locuri}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 my-2">
                    <div className="row mb-1">
                      <div className="col-6">
                        <div className="font-weight-semibold">Culoare:</div>
                      </div>
                      <div className="col-6">
                        <div>{projectData.culoare}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 my-2">
                    <div className="row mb-1">
                      <div className="col-6">
                        <div className="font-weight-semibold">Garanție:</div>
                      </div>
                      <div className="col-6">
                        <div>{projectData.garantie} Luni</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 my-2">
                    <div className="row mb-1">
                      <div className="col-6">
                        <div className="font-weight-semibold">Primul Propietar:</div>
                      </div>
                      <div className="col-6">
                        <div>{projectData.propietar ? "DA" : "NU"}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 my-2">
                    <div className="row mb-1">
                      <div className="col-6">
                        <div className="font-weight-semibold">Înmatriculat:</div>
                      </div>
                      <div className="col-6">
                        <div>{projectData.inmatriculat ? "DA" : "NU"}</div>
                      </div>
                    </div>
                  </div>
                

                  { projectData.inmatriculare &&
                  <div className="col-md-6 my-2">
                    <div className="row mb-1">
                      <div className="col-6">
                        <div className="font-weight-semibold">Data înmatriculări</div>
                      </div>
                      <div className="col-6">
                        <div>{projectData.inmatriculare} </div>
                      </div>
                    </div>
                  </div>
                   }
                  <div className="col-md-6 my-2">
                    <div className="row mb-1">
                      <div className="col-6">
                        <div className="font-weight-semibold">Fară accident în istoric:</div>
                      </div>
                      <div className="col-6">
                        <div>{projectData.accident ? "DA" : "NU"}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 my-2">
                    <div className="row mb-1">
                      <div className="col-6">
                        <div className="font-weight-semibold">Carte service:</div>
                      </div>
                      <div className="col-6">
                        <div>{projectData.service ? "DA" : "NU"}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 my-2">
                    <div className="row mb-1">
                      <div className="col-6">
                        <div className="font-weight-semibold">Țară de origine:</div>
                      </div>
                      <div className="col-6">
                        <div>{projectData.tara} </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            

              <div className="product__features mb-4 mb-xl-5 " >

                <div className="title mb-3">Caracteristici</div>

                <div className="mb-4">
                  <div className="font-weight-semibold text-muted mb-2 mb-md-3">
                    Comfort și Opționale
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="feature-list">
                        {trueComfortOptions.map((option, index) => (
                          <div className="mb-2 d-flex" key={index}>
                            <span className="text-primary font-weight-bold mr-2">-</span>
                            <span>{option}</span>
                          </div>
                        ))}
                      </div>
                    </div>

                  </div>
                </div>

                <div className="mb-4">
                  <div className="font-weight-semibold text-muted mb-2 mb-md-3">
                    Audio și tehnologie
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="feature-list">
                        {trueAudioOptions.map((option, index) => (
                          <div className="mb-2 d-flex" key={index}>
                            <span className="text-primary font-weight-bold mr-2">-</span>
                            <span>{option}</span>
                          </div>
                        ))}

                      </div>
                    </div>

                  </div>
                </div>

                <div className="mb-4">
                  <div className="font-weight-semibold text-muted mb-2 mb-md-3">
                    Siguranță și Performanță
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="feature-list">
                        {trueSigurantaOptions.map((option, index) => (
                          <div className="mb-2 d-flex" key={index}>
                            <span className="text-primary font-weight-bold mr-2">-</span>
                            <span>{option}</span>
                          </div>
                        ))}

                      </div>
                    </div>

                  </div>
                </div>
                <div className="mb-4">
                  <div className="font-weight-semibold text-muted mb-2 mb-md-3">
                    Asistență și Electronice
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="feature-list">
                        {trueAsistenaOptions.map((option, index) => (
                          <div className="mb-2 d-flex" key={index}>
                            <span className="text-primary font-weight-bold mr-2">-</span>
                            <span>{option}</span>
                          </div>
                        ))}

                      </div>
                    </div>

                  </div>
                </div>
                <div className="mb-4">
                  <div className="font-weight-semibold text-muted mb-2 mb-md-3">
                   Descriere
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-lg-6">
                      <div className="feature-list">
                      {projectData.descriere.split('\n').map((paragraph, index) => (
    <div key={index}>{paragraph}</div>
  ))}

                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="product__description">
              </div>
            </div>
  )
}

export default CaracteristiciAuto