import React from 'react'
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'

const Politica = () => {
  return (
    <>
    <Navbar/>
    <div className='container'>
        <section className="">
        <div className="container">
            <div className="row">
                <div className="col-md-12 mb-5">
                    <h3 className="text-center text-uppercase font-weight-bold">Politica de confidentialitate</h3>
                </div>

                <div className="col-md-12">
                    <p><span className="ml-5"></span>Avand in vedere Regulamentul General privind Protecția Datelor” sau
                        „GDPR” ,
                        Regulamentul (UE) 2016/679 al Parlamentului European
                        şi al Consiliului din 27 aprilie 2016 privind protecția
                        persoanelor fizice în ceea ce privește prelucrarea datelor cu
                        caracter personal și privind libera circulație a acestor
                        date și de abrogare a Directivei 95/46/CE, publicat în Jurnalul Oficial
                        al Uniunii Europene L 119 (4.5.2016) şi aplicabil de la 25 mai 2018,
                        va transmitem acest document pentru a va detalia cand si de
                        ce anume va prelucram datele dumneavoastra personale,
                        cum le folosim, conditiile in care le putem divulga altora
                        si cum le stocam in conditii de siguranta.
                    </p>

                    <h4 className="my-4 ml-5">PRELUCRAREA DATELOR CU CARACTER PERSONAL ȘI ROLUL PĂRȚILOR</h4>

                    <p><span className="ml-5"></span>În contextul încheierii și executării Contractului,
                        Părțile vor prelucra o serie de date cu caracter personal, respectiv:
                        datele cu caracter personal ale persoanelor de contact ale Clientului
                        dezvăluite de Client către MDB COMPANY SRL
                        si datele cu caracter personal ale persoanelor de contact
                        ale MDB COMPANY SRL dezvăluite Clientului;
                    </p>

                    <h4 className="my-4 ml-5">Care sunt datele cu caracter personal?</h4>

                    <p><span className="ml-5"></span>Orice informatie prin care o persoana fizica devine identificabila,
                        cum ar fi: un nume, un numar de identificare, date de localizare,
                        un identificator online, unul sau mai multe elemente specifice
                        identitatii sale fizice, fiziologice, genetice, psihice, economice,
                        culturale sau sociale etc.
                    </p>

                    <h4 className="my-4 ml-5">Ce inseamna prelucrarea de date cu caracter personal?</h4>

                    <p><span className="ml-5"></span>Orice operatiune sau set de operatiuni efectuate asupra
                        datelor cu caracter personal sau asupra seturilor de date cu caracter personal,
                        cu sau fara utilizarea de mijloace automatizate,
                        cum ar fi: colectarea, inregistrarea, organizarea, structurarea,
                        stocarea, adaptarea, modificarea, extragerea, consultarea,
                        utilizarea, dezvaluirea prin transmitere (inclusiv catre terti),
                        diseminare sau punere la dispozitie in orice alt mod,
                        alaturarea/alinierea ori combinarea, blocarea/restrictionarea, stergerea sau distrugerea
                    </p>

                    <h4 className="my-4 ml-5">Care sunt riscurile generate de o prelucrare necorespunzatoare si neconforma
                        cu prevederile legale nationale si europene</h4>

                    <p><span className="ml-5"></span>Riscul la care Regulamentul (UE) 2016/679 si Legea nr. 677/200,
                        cu modificările şi completările ulterioare se refera este definit astfel:<br/>
                        ,,Riscul [..] poate fi rezultatul unei prelucrări a datelor
                        cu caracter personal care ar putea genera prejudicii de natură fizică,
                        materială sau morală, în special în cazurile în care: prelucrarea
                        poate conduce la discriminare, furt sau fraudă a identității,
                        pierdere financiară, compromiterea reputației,
                        pierderea confidențialității datelor cu caracter personal protejate
                        prin secret profesional, inversarea neautorizată a pseudonimizării
                        sau la orice alt dezavantaj semnificativ de natură economică sau socială,,
                        ( Regulamentul (UE) 2016/679 privind protecția persoanelor
                        fizice în ceea ce privește prelucrarea datelor cu caracter personal
                        și privind libera circulație a acestor date)
                    </p>


                    <h4 className="my-4 ml-5">Vă prelucrăm date cu caracter personal în scopuri determinate</h4>

                    <p><span className="ml-5"></span>Vă prelucrăm datele cu caracter personal în vederea
                        executării contractului cu entitatea juridică sau persoana fizică,
                        în numele căreia acționați (care include și interacțiunile
                        anterioare semnării acestuia) și pentru îndeplinirea obligațiilor
                        noastre în calitate de parte contractantă.
                    </p>

                    <p><span className="ml-5"></span>Deasemenea va putem transmite oferte, promotii,
                        mesaje publicitare si de marketing prin e-mail, fara alte obligatii
                        sau plati din partea niciunei parti sau cu acordul
                        dumneavoastra sa beneficiati de organizarea de concursuri si campanii publicitare cu premii.
                    </p>

                    <p><span className="ml-5"></span>În plus, vă prelucrăm datele cu caracter personal în vederea
                        satisfacerii unor interese legitime, de exemplu:</p>

                    <ul>
                        <li>prevenirea, detectarea precum și analizarea și gestionarea riscurilor comerciale;</li>
                        <li>în legătură cu orice pretenție, acțiune sau procedură
                            (inclusiv, dar fără a ne limita la, redactarea și
                            revizuirea documentelor, întocmirea documentației
                            necesare încheierii unei tranzacții, obținerea de
                            consultanță juridică și facilitarea soluționării litigiilor)
                            și/sau pentru protejarea sau exercitarea drepturilor
                            și obligațiilor noastre contractuale și legale;</li>
                        <li>cooperarea cu autoritățile și instituțiile publice;</li>
                        <li>efectuarea de statistici interne, cu scopul de a imbunatati activitatea companiei si a oferi
                            oferte cat mai coerente si personalizate;</li>
                    </ul>

                    <p><span className="ml-5"></span>Vom prelucra datele cu caracter personal pe perioada
                        existentei contului persoanei vizate, si pe perioada
                        garantiei produselor achizitionate, iar ulterior stergerii
                        contului persoanei vizate, societatea va transforma datele
                        cu caracter personal in date anonime si le va prelucra in
                        scopuri statistice interne, pe durata existentei societatii.
                    </p>

                    <h4 className="my-4 ml-5">Cine mai poate prelucra date cu caracter personal in numele nostru?</h4>

                    <p><span className="ml-5"></span>Societatea va depune toate eforturile rezonabile pentru a
                        vă proteja datele cu caracter personal aflate în posesia
                        sau sub controlul nostru prin stabilirea unor măsuri
                        rezonabile de securitate în vederea prevenirii accesării,
                        colectării, utilizării, divulgării, copierii, modificării
                        sau aruncării neautorizate, precum și a altor riscuri similare.
                    </p>

                    <p><span className="ml-5"></span>Societatea noastră poate imputernici firmele de curierat
                        sa prelucreze astfel de date pentru livrarea produselor,
                        in acelasi timp in procesul de colectare si prelucrare
                        a datelor cu caracter personal mai sunt implicati si angajatii nostri.
                    </p>

                    <p><span className="ml-5"></span>De asemenea, societatea poate furniza datele cu caracter personal
                        altor companii cu care se afla in relatii de parteneriat,
                        dar numai in temeiul unui angajament de confidentialitate din partea acestora,
                        prin care garanteaza ca aceste date sunt pastrate in siguranta
                        si ca furnizarea acestor informatii personale se face conform
                        legislatiei in vigoare, dupa cum urmeaza: furnizori de servicii de marketing,
                        curierat, servicii de plata/bancare, telemarketing
                        sau alte servicii, furnizate de companii cu care putem dezvolta
                        programe comune de ofertare pe piata Bunurilor si Serviciilor noastre, asiguratori.
                    </p>

                    <p><span className="ml-5"></span>Informatiile dumneavoastra, respectiv datele cu caracter
                        personal pot fi furnizate si catre Parchetul General,
                        Politie, instantele judecatoresti si altor organe
                        abilitate ale statului, in baza si in limitele prevederilor
                        legale si ca urmare a unor cereri expres formulate.
                    </p>


                    <h4 className="my-4 ml-5">DREPTURILE SI OBLIGATIILE PĂRTILOR</h4>

                    <p><span className="ml-5"></span>În prelucrarea datelor cu caracter personal conform Contractului,
                        Părțile se angajează să respecte toate obligațiile aplicabile
                        acestora în calitate de operatori de date cu caracter personal
                        conform legislației privind protecția datelor cu caracter
                        personal inclusiv, dar fără limitare, conform prevederilor GDPR
                    </p>


                    <p><span className="ml-5"></span>2.1. prelucrează datele cu caracter personal ale Persoanelor
                        de Contact ale Clientului în scopul încheierii și executării Contractului,
                        precum și în scopul transmiterii de comunicări
                        Persoanelor de Contact ale Clientului, inclusiv comunicări
                        comerciale din partea MDB COMPANY SRL.
                    </p>


                    <p><span className="ml-5"></span>2.2. Se conformează obligațiilor ce-i revin în calitate
                        de operator în ceea ce privește încălcarea securității
                        datelor cu caracter personal și totodată, informează
                        Clientul cu privire la orice astfel de încălcare a securității
                        datelor cu caracter personal fără întârziere nejustificată.
                    </p>

                    <p><span className="ml-5"></span>2.3. Desemnează un responsabil cu protecția datelor
                        cu caracter personal, care poate fi contactat de către Client,
                        pentru aspecte legate de protecția datelor cu caracter personal,
                        la următoarea adresă de e-mail: office@parccautotimisoara.ro;
                    </p>

                    <p><span className="ml-5"></span>2.4. Prelucrează datele cu caracter personal ale persoanelor
                        de contact ale Clientului pentru scopul încheierii și
                        executării Contractului, asigurându-se astfel că respectă
                        cerințele legislației prin utilizarea unui temei adecvat
                        al prelucrării datelor și asigurarea informării adecvate
                        a MDB COMPANY SRL, conform acestui document;
                    </p>

                    <p><span className="ml-5"></span>2.5. In calitate de operator de date cu caracter personal,
                        este responsabil de implementarea unor măsuri tehnice
                        și organizatorice adecvate pentru a asigura securitatea
                        și confidențialitatea datelor cu caracter personal,
                        tuturor clientiilor care furnizeaza date cu caracter personal
                        cat si persoanelor de contact ale MDB COMPANY SRL,
                        mentionate in contract sau in alte documente.
                    </p>

                    <p><span className="ml-5"></span>2.6. În cazul în care este obligat conform legislației aplicabile,
                        desemnează un responsabil cu protecția datelor cu caracter
                        personal și comunică datele de contact la care acesta
                        poate fi contactat de către MDB COMPANY SRL pentru
                        aspecte legate de protecția datelor cu caracter personal;
                    </p>

                    <p><span className="ml-5"></span>Cu excepția cazului în care legea prevede altfel, aveți următoarele
                        drepturi:</p>

                    <p><span className="ml-5"></span>1. Dreptul de acces, respectiv dreptul de a obține o confirmare
                        din partea noastră că prelucrăm datele dvs. cu caracter personal,
                        precum și accesul la acestea și furnizarea de informații
                        despre modul de prelucrare;
                    </p>

                    <p><span className="ml-5"></span>2. Dreptul la rectificare, care se referă la corectarea,
                        fără întârzieri nejustificate, a datelor cu caracter
                        personal inexacte și/sau la completarea datelor incomplete;
                    </p>

                    <p><span className="ml-5"></span>3. Dreptul la ștergere/dreptul de a fi uitat,
                        adică dreptul de vă șterge fără întârzieri nejustificate datele
                        cu caracter personal colectate, în cazul în care
                        aceste date nu mai sunt necesare pentru îndeplinirea
                        scopurilor pentru care au fost colectate și nu există
                        alt temei juridic pentru prelucrare, datele au fost colectate
                        ilegal sau datele trebuie șterse pentru respectarea unei obligații legale;
                    </p>

                    <p><span className="ml-5"></span>4. Dreptul la restricționarea prelucrării sau sa va opuneti,
                        care se aplică în cazul în care dumneavoastră contestați
                        exactitatea datelor cu caracter personal, prelucrarea este ilegală.
                    </p>

                    <p><span className="ml-5"></span>5. Dreptul de portabilitate, respectiv dreptul dumneavoastră
                        de a primi datele cu caracter personal, pe care ni le-ați
                        furnizat în scopurile indicate în prezenta, într-un format
                        structurat, utilizat în mod curent și care poate fi citit
                        automat, precum și dreptul de a trimite aceste date unui alt operator;
                    </p>

                    <p><span className="ml-5"></span>6. Dreptul de a depune o plângere la Autoritatea Națională de
                        Supraveghere a Protecției Datelor cu Caracter Personal (ANSPDCP)
                        la adresa poștală b-dul. G-ral. Gheorghe Magheru nr. 28-30,
                        sector 1, 010336 București sau la adresa de e- mail anspdcp@dataprotection.ro;
                    </p>

                    <p><span className="ml-5"></span>7. Dreptul la informare – puteti solicita informatii
                        privind activitatile de prelucrare a datelor dumneavoastra personale;
                    </p>

                    <p><span className="ml-5"></span>Dreptul de opozitie – puteti sa va opuneti, in special,
                        prelucrarilor de date care se intemeiaza pe interesul nostru legitim;
                    </p>

                    <p><span className="ml-5"></span>9. Dreptul de retragere a consimtamantului – in cazurile in care
                        prelucrarea se intemeiaza pe consimtamantul dumneavoastra,
                        il puteti retrage oricand. Retragerea consimtamantului va avea
                        efecte doar pentru viitor, prelucrarea efectuata anterior
                        retragerii ramanand in continuare valabila;
                    </p>

                    <p><span className="ml-5"></span>Cu excepția dreptului de a formula o plângere la ANSPDCP,
                        conform celor de mai sus, aceste drepturi pot fi exercitate
                        prin trimiterea unei solicitări scrise la sediulMDB COMPANY SRL, TIMIȘOARA, CALEA DOROBANȚILOR NR. 92 - 94;
                    </p>

                    <h4 className="my-4 ml-5">MASURI DE CONFIDENTIALITATE PENTRU PROTECTIA DATELOR CU CARACTER
                        PERSONAL </h4>

                    <p><span className="ml-5"></span>3.1. Ne asigurăm că drepturile persoanelor vizate legate de
                        prelucrarea datelor personale deținute de noi sunt respectate
                        și că oferim mijloacele necesare pentru a le permite să își exercite drepturile.
                    </p>

                    <p><span className="ml-5"></span>3.2. Oferirea de informații persoanelor vizate – oferim persoanelor
                        vizate informații clare și ușor accesibile cu privire
                        la operatorul de date și prelucrarea datelor lor personale.
                    </p>

                    <p><span className="ml-5"></span>3.3. Asigurarea unui mecanism pentru modificarea sau retragerea
                        consimțământului
                        – oferim un mecanism pentru ca persoanele vizate să
                        modifice sau să-și retragă consimțământul.
                    </p>


                    <p><span className="ml-5"></span>3.4. Asigurarea unui mecanism pentru a se opune
                        prelucrării – oferim un mecanism care permite persoanelor vizate
                        să se opună prelucrării datelor lor personale.
                    </p>

                    <p><span className="ml-5"></span>3.5. Împărtășirea exercitării drepturilor de principiu cu privire
                        la datele cu caracter personal - luăm măsuri rezonabile
                        pentru a informa terții cu care au fost împărtășite datele
                        cu caracter personal, despre orice modificare,
                        retragere sau obiecții care rezultă din exercitarea
                        drepturilor persoanelor vizate.
                    </p>

                    <p><span className="ml-5"></span>3.6. Rectificarea sau ștergerea – implementăm un mecanism
                        pentru a facilita exercitarea drepturilor persoanelor
                        vizate să acceseze, să corecteze și/sau să șteargă
                        datele lor cu caracter personal.
                    </p>

                    <p><span className="ml-5"></span>3.7. Oferirea unor copii a datelor cu caracter personal
                        prelucrate – putem oferi o copie a datelor cu caracter
                        personal prelucrate, supusă politicii de reținere
                        și ștergere, atunci când se solicită de către un
                        responsabil cu datele cu caracter personal.
                    </p>

                    <p><span className="ml-5"></span>3.8. Limitarea colectării – limităm colectarea
                        datelor personale la minimul relevant, proporțional
                        și necesar pentru scopurile identificate.
                    </p>

                    <p><span className="ml-5"></span>3.9. Limitarea prelucrării – limităm prelucrarea datelor
                        cu caracter personal la ceea ce este adecvat,
                        relevant și necesar pentru scopurile identificate.
                    </p>

                    <p><span className="ml-5"></span>3.10. Reținerea – nu reținem date cu caracter personal
                        mai mult decât este necesar în scopul pentru
                        care s-au prelucrat datele cu caracter personal.
                    </p>

                    <p><span className="ml-5"></span>3.11. Eliminarea – se va face in conditii de siguranta si pentru a
                        evita si preveni alte riscuri.</p>

                    <h4 className="my-4 ml-5">SECURITATEA DATELOR CU CARACTER PERSONAL</h4>

                    <p><span className="ml-5"></span>Societatea va depune toate eforturile rezonabile pentru
                        a vă proteja datele cu caracter personal aflate în posesia
                        sau sub controlul nostru prin stabilirea unor măsuri
                        rezonabile de securitate în vederea prevenirii accesării,
                        colectării, utilizării, divulgării, copierii,
                        modificării sau aruncării neautorizate, precum și a altor riscuri similare.
                    </p>

                    <p><span className="ml-5"></span>Deasemenea societatea garanteaza securitatea si
                        confidentialitatea datelor gazduite si transmise prin sistemul
                        sau informatic, declara ca sunt protejate bazele
                        de date inclusiv prin criptare electronica, au fost
                        incheiate protocoale de colaborare cu societatile informatice
                        care gestioneaza pagina noastra de web, s-au prevazut
                        specificatii de securizare a datelor in contractele de
                        colaborare precum si in procedurile interne si exista
                        prevederi de confidentialitate in contractele angajatilor nostri.
                    </p>

                </div>
            </div>
        </div>
    </section>

    </div>
        
   <Footer/>
    </>
  )
}

export default Politica