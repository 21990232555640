import React, { useRef, useState } from 'react';
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import emailjs from "emailjs-com";


const Contact = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState("")
    const [telefon, setTelefon] = useState("")
    const [mesaj, setMesaj] = useState("")

    const [showModal, setShowModal] = useState(false)


    const handleEmail = (event) => {
        const inputText = event.target.value;
        // Convertim textul în litere mari (uppercase)
        
        setEmail(inputText);
    };
    const handleTelefon = (event) => {
        const inputText = event.target.value;
      
        setTelefon(inputText);
    };
    const handleMesaj = (event) => {
        const inputText = event.target.value;
      
        setMesaj(inputText);
    };

    const handleInputChange = (event) => {
        const inputText = event.target.value;
        // Convertim textul în litere mari (uppercase)
        const upperCaseText = inputText.toUpperCase();
        setName(upperCaseText);
    };

    const form = useRef();
    const [isSent, setIsSent] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        setShowModal(true)
        emailjs
            .sendForm('service_59jylcb', 'template_315n205', form.current, 'LyelvpzMoW7re-5oT')
            .then(
                (result) => {
                    console.log(result.text);
                    setIsSent(true);
                    setTimeout(() => {
                        // Închide modalul după 3 secunde
                    }, 3000);
                },
                (error) => {
                    console.log(error.text);
                }
               
            );
            setEmail("")
            setName("")
            setMesaj("")
            setTelefon("")
            setShowModal(false)
    };

    return (
        <div>

            <Navbar />

            <section className="py-4 bg-light text-black"  >
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="font-md text-uppercase">Contact </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="">
            { showModal && (
      <div className='modalLoading'>
        <div className="spinner-border  text-light" >
          <span className="visually-hidden"></span>
        </div>
          
      </div>
        )}
                <div className="container mt-4">
                    <div className="row">

                        <form ref={form} onSubmit={sendEmail} className="col-sm-6">
                            <div className="contact-form p-md-4 p-lg-5">
                                <div className="title title-small text-primary mb-3">Scrie-ne</div>
                                <div className='' >

                                    <div className="row">
                                        <div className="col-md-12 mb-4">
                                            <div className="form-group">
                                                <label className="has-float-label" htmlFor='name'>

                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        required
                                                        id="name"
                                                        placeholder="&nbsp;"
                                                        value={name} // Valoarea este setată cu textul convertit în litere mari
                                                        onChange={handleInputChange} // La fiecare modificare a inputului, apelăm handleInputChange
                                                    />
                                                    <span>Nume</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-xl-6 mb-4">
                                            <div className="form-group">
                                                <label className="has-float-label" htmlFor='phone'>
                                                    <input type="text" className="form-control" name="phone" id="phone" value={telefon}
                                                    onChange={handleTelefon}
                                                    required
                                                        placeholder="&nbsp;" />
                                                    <span>Telefon</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-md-12 col-xl-6 mb-4 ">
                                            <div className="form-group ">
                                                <label className="has-float-label" htmlFor='email'>
                                                    <input type="email" className="form-control" name="email" id="email"
                                                    value={email}
                                                    onChange={handleEmail}
                                                        required="" placeholder="&nbsp;" />
                                                    <span>Email</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group mb-4">
                                        <label className="has-float-label" htmlFor='message'>
                                            <textarea className="form-control h-auto pt-4" name="message" id="message" rows="8"
                                            value={mesaj}
                                            onChange={handleMesaj}
                                                required placeholder="&nbsp;"></textarea>
                                            <span>Mesajul tău</span>
                                        </label>
                                    </div>


                                    <div className="col-md-12 col-xl-12 mb-4 text-center">
                                        <button
                                            id="submit"
                                            className="btn btn-primary">
                                            Trimite
                                        </button>
                                    </div>
                                    {
                                      isSent && 
                                       <p className='text-primary text-bold'> Mesaj trimis cu succes!</p>
                                    }
                                </div>
                            </div>

                        </form>

                        <div className="col-sm-6 ">

                            <div className="col-md-12 col-xl-12 mb-3">
                                <div className="contact__box  border border-gray px-2 py-4 text-center rounded-lg  mb-4 mb-xl-0">
                                    <span className="fa-solid fa-location-dot fa-xl text-primary mb-2 d-inline-block"></span>
                                    <div className="font-md font-weight-bold mb-2">Adresă</div>
                                    <div className="text-black box-desc">
                                        <a target='_blank' href="https://www.google.com/maps/place/MDB+Company/@45.7690535,21.2652434,17z/data=!3m1!4b1!4m20!1m13!4m12!1m4!2m2!1d21.3122726!2d46.1865618!4e1!1m6!1m2!1s0x474567156576a703:0xc3229aae8a062af5!2smdb+timisoara!2m2!1d21.2678338!2d45.7690161!3m5!1s0x474567156576a703:0xc3229aae8a062af5!8m2!3d45.7690535!4d21.2678183!16s%2Fg%2F11cnd722s_?entry=ttu">
                                            TIMIȘOARA, CALEA DOROBANȚILOR NR. 92 - 94
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-xl-12 mb-3">
                                <div className="contact__box border border-gray px-2 py-3 text-center rounded-lg mb-4 mb-xl-0">
                                    <span className="fa-solid fa-phone fa-xl text-primary mb-2 d-inline-block"></span>
                                    <div className="font-md font-weight-bold mb-2">Telefon</div>
                                    <div className="text-black box-desc">
                                        <a href="tel:+40729475100">0729 475 100</a> <br />
                                        <a href="tel:+40727521311">0727 521 311</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-xl-12 mb-3 ">
                                <div className="contact__box  border border-gray px-2 py-4 text-center rounded-lg mb-4 mb-lg-0">
                                    <span className="fa-solid fa-envelope fa-xl text-primary mb-2 d-inline-block"></span>
                                    <div className="font-md font-weight-bold mb-2">Email</div>
                                    <div className="text-black box-desc">
                                        <a href="mailto:office@parcautotimisoara.ro">office@parcautotimisoara.ro</a>  <br />
                                       
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 col-xl-12 mb-3 col-sm-12 ">
                                <div className="contact__box  border border-gray px-2 py-4 text-center rounded-lg">
                                    <span className="fa-solid fa-clock fa-xl text-primary mb-2 d-inline-block"></span>
                                    <div className="font-md font-weight-bold mb-2">Orar</div>
                                    <div className="text-black box-desc">
                                        Luni - Vineri: <br className="d-xl-none d-md-none d-sm-none" /> 09:00 - 18:00   <br />
                                        Sâmbătă: <br className="d-xl-none d-md-none d-sm-none" /> 10:00 - 16:00  <br />
                                        Duminică: <br className="d-xl-none d-md-none d-sm-none" /> 10:00 - 14:00
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>



                </div>
            </section>
            <div className="contact-map mt-4">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11132.426470227463!2d21.2678183!3d45.7690535!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x474567156576a703%3A0xc3229aae8a062af5!2sMDB%20Company!5e0!3m2!1sen!2sro!4v1698495183011!5m2!1sen!2sro"
                    width="100%" height="450" style={{ border: "0" }} allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <Footer />

        </div>
    )
}

export default Contact