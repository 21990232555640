import React ,{useEffect} from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';

const SectionHome = ({carmoney}) => {
useEffect(()=> {
    AOS.init()
},[])

  return (
                    <section className="banner-credit" style={{ backgroundImage: `url("${carmoney}")` }}>
                    <div className="container my-4 my-md-5">
                        <div className="row">
                            <div className="col-md-10 col-lg-8 col-xl-6 " data-aos="fade-up">
                                <div className="credit-info bg-white rounded-lg p-4 p-md-5 ">
                                    <span className="fa-solid fa-hand-holding-dollar icon text-primary mb-2 mb-md-3 mb-xl-4 d-inline-block" ></span>
                                    <div className="title mb-4"  >Dacă nu dispui de toți banii necesari, nicio problemă! Îți oferim mai multe soluții de finanțare.
                                    </div>
                                    <div className="description">
                                        <p >
                                            Dacă ai pus ochii pe mașina visurilor tale, dar bugetul tău nu este suficient pentru a o achiziționa, nu trebuie să renunți la acest vis.
                                        </p>
                                        <p >
                                            Echipa noastră poate să te ajute să găsești cea mai bună soluție de finanțare, care să fie adaptată nevoilor tale, astfel încât să poți conduce mașina mult dorită.
                                        </p>
                                        <a href="/credit-rate" className="btn btn-primary mt-3" data-aos="flip-up"  data-aos-duration="2000">Mai multe detalii</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
  
  )
}

export default SectionHome