import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import GearboxIcon from "../Componente/GearBoxIcon"



const PretTelefon = ({ projectData }) => {

  const [facebookShareURL, setFacebookShareURL] = useState('');
  const { masinaId } = useParams();


  useEffect(() => {
    // Obține URL-ul curent al browser-ului
    const currentURL = window.location.href;
    // Construiește URL-ul pentru partajare pe Facebook folosind URL-ul curent
    const facebookURL = `https://www.facebook.com/sharer/sharer.php?u=${currentURL}`;
    setFacebookShareURL(facebookURL);
  }, []);



  return (
    <div className="col-md-5 col-xl-5 details-left">

      <div className="pr-xxl-5 mr-xxl-5">
        <h1 className="product__name mb-1">
          {projectData.marca} {projectData.model}  
          
        </h1>
        <h1 className="product__name mb-4">
  {projectData.titlu.length <= 40 ? projectData.titlu : projectData.titlu.slice(0, 40) + '...'}
</h1>

        <div className="d-flex align-items-center pb-4 border-bottom mb-4">
          <div className="d-flex align-items-center ">
            <span className="fa-solid fa-gas-pump text-primary font-xl mr-2"></span>
            <span className="font-xs font-weight-light">{projectData.combustibil}</span>
          </div>
          <div className="d-flex align-items-center mx-2">
            <span className="text-primary font-xl mr-2" style={{ marginTop: '-5px' }}>  <GearboxIcon /></span>
            <span className="font-xs font-weight-light">{projectData.cutieViteza}</span>
          </div>
          <div className="d-flex align-items-center">
            <span className="fa fa-road text-primary font-xl mr-2"></span>
            <span className="font-xs font-weight-light">{projectData.rulaj.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} Km</span>
          </div>
        </div>

        <div className="row">
          <div className="col-6-or-not col">
            {projectData.sasiu && (
              <div className='text-muted'>
                VIN(serie șasiu): {projectData.sasiu}
              </div>
            )
            }
            <p className="h3 mb-0">
              Preț:
            </p>
            <div className=" mb-4">
            <div className='text-primary'>
  {projectData.pretCuTVA ? (
    <>
      <div>
        <span className='h4' style={{ textDecoration: projectData.pretNou ? 'line-through' : 'none' }}>
          {projectData.pret.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €
        </span>
        <span className='h6 text-dark'>fără TVA</span><br />
        <span className='h4' style={{ textDecoration: projectData.pretNou ? 'line-through' : 'none' }}>
          {(parseFloat(projectData.pret) * 1.19).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €
        </span>
        <span className='h6 text-dark'>cu TVA</span>
      </div>
    </>
  ) : (
    <>
    <div>
    <span className='h4' style={{ textDecoration: projectData.pretNou ? 'line-through' : 'none' }}>
      {projectData.pret.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €
    </span>
  </div>
    </>
  )}

  {projectData.pretNou && (
    <>
      <div className='text-primary'>
      <p className="h3 mb-0 text-black">
              Preț redus:
            </p>
        <span className='h4'>
          {projectData.pretNou.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €
        </span>
        {projectData.pretCuTVA ? (
          <>
            <span className='h6 text-dark' >fără TVA</span><br />
            <span className='h4'>
              {(parseFloat(projectData.pretNou) * 1.19).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €
            </span>
            <span className='h6 text-dark'>cu TVA</span>
          </>
        ) : (
          <span className='h6 text-dark'></span>
        )}
      </div>
    </>
  )}
</div>






            </div>
          </div>
        </div>




        <div className="call rounded-md mb-2 mt-xl-5  bg-primary  text-white ">

          <a href="tel:+40729475100" className="p-3 d-flex align-items-center justify-content-center " title="Apel telefonic">
            <span className="fa-solid fa-phone mr-2"></span>
            <span>0729 475 100</span>
          </a>
        </div>
        <div className="call rounded-md mb-2 mt-xl-1 bg-primary  text-white ">

<a href="tel:+400727521311" className="p-3 d-flex align-items-center justify-content-center " title="Apel telefonic">
  <span className="fa-solid fa-phone mr-2"></span>
  <span>0727 521 311</span>
</a>
</div>
        

        <a href="https://wa.me/40729475100" target="_blank" className="p-3 d-flex align-items-center justify-content-center bg-whatsapp-green rounded-md text-black mb-2 h6" title="Mesaj pe WhatsApp">
          <i className="fa-brands fa-whatsapp mr-4 font-xl"></i>
          <span>Scrie-ne pe WhatsApp</span>
        </a>

        <a href="sms:+40729475100" className="p-3 d-flex align-items-center justify-content-center rounded-md call mb-4"  title="Direct SMS">
          <i className="fa-solid fa-paper-plane  mr-4 font-lg"></i>
          <span>Trimite un mesaj</span>
        </a>


      </div>
    </div>

  )
}

export default PretTelefon