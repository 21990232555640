
import { useParams } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { initializeApp } from 'firebase/app';
import { app, auth, firestore, storage } from '../firebase';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Navbar from '../Header/Navbar';
import PretTelefon from "./PretTelefon"
import CaracteristiciAuto from "./CaracteristiciAuto"
import "../style.css"
import { Navigation, Zoom } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/zoom';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Footer from '../Footer/Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const DetaliMasina = () => {

  const { masinaId } = useParams(); // Preia projectId din URL
  const [images, setImages] = useState([]);
  const [mainImage, setMainImage] = useState([]);
  const [projectData, setProjectData] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [showFooter, setShowFooter] = useState(true)
  const [showrest, setShowRest] = useState(true)

  const openImageModal = (imageIndex) => {
    setSelectedImageIndex(imageIndex);
    setShowImageModal(true);
    setShowFooter(false)
    setShowRest(false)
  };

  const closeImageModal = () => {
    setShowImageModal(false);
    setShowFooter(true)
    setShowRest(true)
  };

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        const projectDocRef = doc(firestore, 'post', masinaId);
        const projectDocSnap = await getDoc(projectDocRef);

        if (projectDocSnap.exists()) {
          const data = projectDocSnap.data();
          setProjectData(data);

          let sortedImagesArray = [];
          let mainImageArray = [];

          // Dacă există `images` și este un obiect, sortează și prelucrează imaginile
          if (data.images && typeof data.images === 'object') {
            sortedImagesArray = Object.keys(data.images)
              .sort()
              .map(key => data.images[key]);
          }

          // Dacă există `mainImage` și este un obiect, prelucrează imaginea principală
          if (data.mainImage && typeof data.mainImage === "object") {
            mainImageArray = Object.values(data.mainImage);
          }

          // Actualizează starea componentelor
          setImages(sortedImagesArray);
          setMainImage(mainImageArray);

          // Actualizează meta tag-ul og:image după ce datele sunt preluate
          const ogImageTag = document.querySelector('meta[property="og:image"]');
          if (ogImageTag && mainImageArray && mainImageArray[0]) {
            let updatedImageUrl = mainImageArray[0].replace(/&amp;/g, '&');
            updatedImageUrl = updatedImageUrl.replace('https://firebasestorage.googleapis.com/v0/b/parcautotimisoara-v1.appspot.com/o/', 'https://storage.googleapis.com/parcautotimisoara-v1.appspot.com/');
            updatedImageUrl = updatedImageUrl.replace(/%2F/g, '/');

            const jpgIndex = updatedImageUrl.search(/\.(jpeg|jpg)/i);
            if (jpgIndex !== -1) {
              updatedImageUrl = updatedImageUrl.slice(0, jpgIndex + 5);
            }

            ogImageTag.content = updatedImageUrl;
          }
        } else {
          console.log('Documentul nu există');
        }
      } catch (error) {
        console.error('Eroare la preluarea datelor din Firestore:', error);
      }
    };

    fetchProjectData(); // Apelarea funcției pentru preluarea datelor
  }, [masinaId]);
 
 
 
  if (!projectData) {
    // Afișează un mesaj sau componentă de încărcare în timp ce se încarcă datele
    return <div>Loading...</div>;
  }

  const pageTitle = `${projectData.marca} ${projectData.model}`;
  const pageDescription = `${projectData.pret} €`;
  const pageUrl = `https://parcautotimisoara.ro/detalimasina/${masinaId}`;


  const comfortOptions = projectData.comfortOptions;
  const audioOption = projectData.audioOptions
  const siguranta = projectData.sigurantaOptions
  const asistenta = projectData.asistentaOptions

  // Obțineți cheile care au valoarea 'true'
  const trueComfortOptions = Object.keys(comfortOptions).filter(key => comfortOptions[key] === true);
  const trueAudioOptions = Object.keys(audioOption).filter(key => audioOption[key] === true);
  const trueSigurantaOptions = Object.keys(siguranta).filter(key => siguranta[key] === true);
  const trueAsistenaOptions = Object.keys(asistenta).filter(key => asistenta[key] === true);


  return (
    <HelmetProvider>
    <div>
     <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta property="og:url" content={pageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageTitle} />
      </Helmet>
      <Navbar />
      {showImageModal && (
        <div className="my-modal">
          <div className="body">
            <span className="close-modal" style={{ zIndex: "9999" }} onClick={closeImageModal}>
              &times;
            </span>
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-1  d-none d-sm-block text-center order-1 ">
                <button className="left-button"  aria-label="Left navigation"> <i className="fa-solid fa-angle-left" style={{ fontSize: "2rem" }}></i></button>
              </div>
              <div className="col-sm-10 order-2" style={{ height: "100%" }}>
                <Swiper
                  modules={[Navigation, Zoom]}
                  spaceBetween={50}
                  slidesPerView={1}
                  zoom={true}
                  navigation={{ nextEl: ".right-button", prevEl: ".left-button" }}
                  initialSlide={selectedImageIndex}
                >

                  {mainImage.map((imageOne, index) => (
                    <SwiperSlide key={index} style={{ height: "700px", objectFit: "contain" }}>
                     <div className="swiper-zoom-container" >
                     <img src={imageOne} alt={` ${imageOne} ${index}`} className='' loading='lazy' />
                      </div>
                    </SwiperSlide>
                  ))

                  }
                  {images.map((image, index) => (
                    <SwiperSlide key={index} style={{ height: "700px", objectFit: "contain" }}>
                      <div className="swiper-zoom-container" >
                        <img src={image} alt={` ${image} ${index}`}className='' loading='lazy' />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              <div className="col-sm-1 d-none d-sm-block text-center order-3 ">
                <button className="right-button"  aria-label="Right navigation"> <i className="fa-solid fa-angle-right" style={{ fontSize: "2rem" }}></i></button>
              </div>
            </div>
          </div>

        </div>
      )}
      {showrest && (
        <section>



          <div className="container">
            <div className="row">
              <div className="col-md-12">

              </div>
            </div>
            <div className="row product-details">
              <div className="col-md-7 col-xl-7 mb-5 mb-lg-0 bg-light2 py-2" style={{display:"flex", justifyContent:"center", alignItems:"center" , borderRadius:"12px" }} >
                
                <Swiper
                  // install Swiper modules
                  modules={[Navigation, Zoom]}
                  spaceBetween={10}
                  slidesPerView={1}
                  loop={false}
                  zoom={true}
                  navigation={{
                    nextEl: ".swiper-button-next", // Selectați clasa sau identificatorul corespunzător pentru butonul "next"
                    prevEl: ".swiper-button-prev" // Selectați clasa sau identificatorul corespunzător pentru butonul "prev"
                  }}

                >

                  {mainImage.map((imageOne, index) => (

                    <SwiperSlide key={index}>
                      <div style={{ height: "500px" }}>
                        <img
                          src={imageOne}
                          alt={` ${imageOne} ${index}`}
                          onClick={() => openImageModal(0)}
                          className="img-fluid"
                          loading='lazy'
                          style={{ borderRadius: "8px", width: "100%", objectFit: "contain", height: "100%" }}
                        />
                      </div>
                    </SwiperSlide>
                  ))

                  }

                  {images.map((image, index) => (
                    <SwiperSlide key={index}

                    >
                      <div className="" style={{ height: "500px" }}>
                        <img src={image} alt={` ${image} ${index}`} onClick={() => openImageModal(index)} className='img-fluid' loading='lazy' style={{ borderRadius: "8px", width: "100%", objectFit: "contain", height: "100%" }} />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>



                <div className="swiper-button-prev" style={{ color: "red", marginLeft: "5px" }}></div>
                <div className="swiper-button-next" style={{ color: "red", marginRight: "5px" }}></div>

              </div>

              <PretTelefon projectData={projectData} />
              <CaracteristiciAuto projectData={projectData} trueAsistenaOptions={trueAsistenaOptions} trueAudioOptions={trueAudioOptions} trueComfortOptions={trueComfortOptions} trueSigurantaOptions={trueSigurantaOptions} />
            </div>
          </div>
        </section>
      )
      }
      <Footer />
    </div>
    </HelmetProvider>
  )
}

export default DetaliMasina;