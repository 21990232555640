import numere from "./assets/benefits/numererosii.svg"
import garantie from "./assets/benefits/garantie.svg"
import livrare from "./assets/benefits/livrare.svg"
import verificate from "./assets/benefits/verificate.svg"
import finantare from "./assets/benefits/finantare.svg"
import inscriere from "./assets/benefits/inscriere.svg"


export const beneficii = [
    {
        nume: "Mașini verificate",
        icon: verificate,
        descriere: "Fiecare vehicul este supus unui proces de verificare și testare înainte de a fi expus în parc pentru vânzare.",
        id:"01"
    },
    {
        nume: " Înscriere autoturism",
        icon: inscriere,
        descriere: "Iti oferim serviciul complet, pana la inmatricularea prin colaboratorii nostri care iti vor oferi preturi avantajoase.",
        id:"02"
    },
    {
        nume: " Numere roșii",
        icon: numere,
        descriere: " Ai posibilitatea pentru a opta pentru plăcuțe de înmatriculare temporare pentru mașina ta nouă, fără nicio plată suplimentară.",
        id:"03"
    },
    {
        nume: "Garanție 12 Luni",
        icon: garantie,
        descriere: " Asigurăm o garanție de 12 luni pentru a-ți oferi liniștea necesară în ceea ce privește starea autoturismului tău. Se aplică doar la autoturismele nu mai vechi de 15 ani.",
        id:"04"
    },
    {
        nume: "Finanțare",
        icon: finantare,
        descriere: "Ai opțiunea de a accesa finanțare online sau la sediul nostru doar cu buletinul. Echipa noastră poate să te ajute să găsești cea mai bună soluție de finanțare.",
        id:"05"
    },
    {
        nume: " Livrare gratuită",
        icon: livrare,
        descriere: "Oferim livrare gratuita pe o rază de 100 km în Timișoara.",
        id:"06"
    },

]