import React ,{useState ,useEffect} from 'react'
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { logoBrands } from './ListaLogoBrand';

const SliderBrandLogo = () => {

  const [slideperView, setSlide] = useState(5);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 576) { // Dacă ecranul are lățimea mai mică sau egală cu 576px (sm)
        setSlide(3);
      } else {
        setSlide(5);
      }
    };

    window.addEventListener('resize', handleResize);
    // Apelăm funcția pentru a seta înălțimea inițială
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div  data-ride="carousel" data-interval="false" data-wrap="false" style={{height:"100px" }}>
    <Swiper className="swiper-wrapper text-center"
        modules={[Navigation, Autoplay]}
        spaceBetween={50}
        slidesPerView={slideperView}
        autoplay={true}
        loop={true}
        style={{height:"100%" ,width:"100%"}}
      
    >
        {logoBrands.map((image, index) => (
            <SwiperSlide key={index}   style={{width:"100%" ,display:"flex", justifyContent:"center",alignItems:"center"}}>
              <img src={image.logo} alt={image.logo} loading='lazy' style={{height:"50%" }}/>
              </SwiperSlide>
        ))}
    </Swiper>
    
</div>
  )
}

export default SliderBrandLogo