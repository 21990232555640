import React, { useState, useEffect } from 'react';



const Search = ({ searchTerm, setSearchTerm, sortOption, setSortOption }) => {

  const zero = 0

  return (

    <>
   
    <div className="row mb-xl-4  justify-content-between">
      <div className="col-md-12 col-xl-2 mb-4 mb-xl-0 ">
        <div className="search">
          <input type="text" value={searchTerm} className="form-control" id="search-bar-brand" placeholder="Caută după marca..." onChange={(e) => setSearchTerm(e.target.value)} />

        </div>
      </div>

      <div className="col-md-12 col-xl-3">

       

        <div className="d-md-flex justify-content-between align-items-md-center mb-md-3 mt-2">

          <div className="d-flex align-items-center justify-content-between mb-4 mb-md-0">
            <div className="text-uppercase font-xs font-weight-semibold text-gray flex-none mr-2 d-none d-md-inline">
              Sortare după
            </div>

            <div className="sort-select select-custom border-0 w-100">
              <select
                name="sort"
                className="form-control"
                value={sortOption} // Valoarea selectată este acum gestionată de statul sortOption
                onChange={(e) => setSortOption(e.target.value)} // Actualizează statul atunci când se schimbă opțiunea
              >

                <option value="">Alege</option>
                <option value="price_low">Pret crescator</option>
                <option value="price_high">Pret descrescator</option>

              </select>
            </div>

          </div>
          
        

        </div>
      </div>
    </div>
    </>
  )
}

export default Search