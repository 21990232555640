import audi from "./assets/brandlogo/audi.png"
import bmw from "./assets/brandlogo/bmw.png"
import ford from "./assets/brandlogo/ford.png"
import hyundai from "./assets/brandlogo/hyundai.png"
import mercedes from "./assets/brandlogo/mercedes.png"
import opel from "./assets/brandlogo/opel.png"
import peugeot from "./assets/brandlogo/peugeot.png"
import renault from "./assets/brandlogo/renault.png"
import skoda from "./assets/brandlogo/skoda.png"
import toyota from "./assets/brandlogo/toyota.png"
import volkswagen from "./assets/brandlogo/volkswagen.png"


export const logoBrands = [
    {
        logo: audi
    },
    {
        logo: bmw
    },
    {
        logo: ford
    },
    {
        logo: hyundai 
    },
    {
        logo: mercedes
    },
    {
        logo: opel
    },
    {
        logo: peugeot
    },
    {
        logo: renault
    },
    {
        logo: skoda
    },
    {
        logo: toyota
    },
    {
        logo: volkswagen
    },
]