import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { app, auth, firestore, storage } from '../firebase';
import { getFirestore, collection, getDocs, query, orderBy  } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Search from '../Listing/Search'

import Pagination from "../Listing/Pagination"
import { Link } from "react-router-dom"
import Navbar from '../Header/Navbar';
import Produs from '../Componente/Produs';
import GearBoxIcon from '../Componente/GearBoxIcon';

import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../Footer/Footer';
import FilterBrand from '../Listing/FilterBrand';


const Masini = () => {

  const [data, setData] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [sortOption, setSortOption] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);





  //Functie deschidere sidebar filtrare
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };


  const [filteredMarca, setFilteredMarca] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const postCollectionRef = collection(firestore, 'post');
        const q = query(postCollectionRef, orderBy('createdAt', 'desc'));
                const querySnapshot = await getDocs(q);
        const dataArray = [];
        querySnapshot.forEach((doc) => {
          const docData = doc.data();
          dataArray.push({ id: doc.id, ...docData }); // Adaugă ID-ul documentului în obiectul de date
        });

        setData(dataArray);
      } catch (error) {
      }
    };
    fetchData();
    AOS.init()
  }, [firestore]);


  const handleFilterByMarca = (marca ) => {
    setFilteredMarca(marca);
    // Filtrați datele aici și actualizați filteredData
    const filteredProjects = marca === '' ? data : data.filter((masina) => masina.marca === marca);
    setFilteredData(filteredProjects);
    if (window.innerWidth <= 768) {
      const brandListWrapper = document.querySelector('.brand-list-wrapper');
      const body = document.querySelector('body');
      brandListWrapper.classList.toggle('list-open');
      body.classList.toggle('overflow-hidden');
    }
  };




  // Funcție pentru Serchbar
  useEffect(() => {
    const filteredProjects = data.filter((masina) =>
      masina.marca.toLowerCase().includes(searchTerm.toLowerCase())

    );
    setFilteredData(filteredProjects);

  }, [searchTerm, data]);


  //Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12; // Numărul de articole pe pagină
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Funcție pentru a schimba pagina
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculați indexul de start și sfârșit pentru rândurile curente
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Obțineți rândurile pentru pagina curentă
  const currentRows = filteredData.slice(startIndex, endIndex);

  const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(orientation: landscape)");

    const handleOrientationChange = (event) => {
      setIsLandscape(event.matches);
    };

    mediaQuery.addEventListener('change', handleOrientationChange);

    return () => {
      mediaQuery.removeEventListener('change', handleOrientationChange);
    };
  }, []);



  return (
    <div>
      <Navbar />
      <section className="py-4 bg-light text-black"  >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="font-md text-uppercase">Lista de mașini</div>
            </div>
          </div>
        </div>
      </section>

          <FilterBrand handleFilterByMarca={handleFilterByMarca}/>
      <section >
        <div className="container" >
          
          

          <div className="">
          <Search searchTerm={searchTerm} toggleFilters={toggleFilters} setSearchTerm={setSearchTerm} setSortOption={setSortOption} />
            {/* lista Masini */}
            <div className="">
              <div className="row">
              {filteredData.length === 0 ? (
  <div className="no-results-message">
    Nu s-au găsit mașini în stoc după această marcă. Puteți lua legătura cu noi pentru a vă aduce o mașină după preferințele dumneavoastră.
  </div>
) : ( 
                filteredData
                  .sort((a, b) => {
                    if (sortOption === 'price_low') {
                      return a.pret - b.pret;
                    } else if (sortOption === 'price_high') {
                      return b.pret - a.pret;
                    }
                    return 0; // Fără sortare (pentru orice altă opțiune în afara de "Crescător", "Descrescător" și "Recomandate")
                  })
                  .slice(startIndex, endIndex)
                  .map((masina, index) => (
                    <div className={`col-md-${isLandscape ? 4 : 6} col-xl-3 product-holder`} key={index}>
                      <div className="product" data-aos="zoom-in">
                        <Link to={`/detalimasina/${masina.id}`}  aria-label="Link către anunț" className="a-absolute"></Link>
                        {masina.mainImage && Object.keys(masina.mainImage).map((key) => (
                          <div className="product__img position-relative" key={`${masina.id}-${key}`}>
                          {/* Container pentru imagine și text */}
                          <div className="image-container">
                            {/* Imagine */}
                            <img src={masina.mainImage[key]} alt={masina.marca} className="img-fluid" loading="lazy" />
                        
                            {/* Div pentru text (Disponibil) */}
                            {masina.comanda ? (
                              <div className="text-overlay">
                              Disponibil pe comandă
                            </div>

                            ) : null

                            }
                          </div>
                        </div>
                        ))}
                        <div className="product__specs bg-secondary px-3 py-3">
                        <div className="mb-2 mb-md-2 mb-lg-2 mb-2">
  {masina.marca} {masina.model} - {masina.anFabricatie} <br/>
  {masina.titlu.length <= 39 ? masina.titlu : masina.titlu.slice(0, 39) + '...'}
</div>


                          <div className="pb-3 border-bottom border-dark-gray mb-3 d-md-flex-or-not d-lg-block d-xxl-flex-or-not d-xxl-block text-gray">
                            <div className="d-flex align-items-center justify-content-between ">
                              <span className="text-light">Garanție</span>
                              <span className="text-light">Km certificați</span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between py-1 ml-auto mt-2 mt-md-0-or-not mt-lg-2 mt-xxl-0-or-not">

                              <div className="product__price px-2">
                                {masina.pret.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €
                              </div>
                              <div className="font-xs  ">
                                <span className="text-light">Posibilitate rate</span>
                              </div>
                            </div>
                          </div>
                          <div className="specs d-flex align-items-center justify-content-between">

                            <div className="d-flex align-items-center">
                              <span className="fa fa-road text-primary mr-2"></span>
                              <span>
                                {masina.rulaj.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} Km
                              </span>
                            </div>

                            <div className="d-flex align-items-center mx-2">
                              <span className="fa-solid fa-gas-pump text-primary mr-2"></span>
                              <span>{masina.combustibil}</span>
                            </div>

                            <div className="d-flex align-items-center">
                              <span className=" text-primary mr-2"><GearBoxIcon /></span>
                              <span>{masina.cutieViteza}</span>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                 ) ))}
              </div>

              {/* Paginni  */}
              <div className="row" >
              {filteredData.length === 0 ? (
  <div className="no-results-message">
   
  </div>
) : ( 

                <Pagination currentPage={currentPage} handlePageChange={handlePageChange} totalPages={totalPages} />
)}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default Masini